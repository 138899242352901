.statsGrid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  margin-bottom: 20px;
}

.stats {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.stat {
  flex-grow: 1;
}

.panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.panel {
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subtitle {
  padding-right: 18px;
}
.chartTitle {
  display: block;
  text-align: center;
  margin-bottom: 4px;
}
.title {
  padding-top: 22px;
  padding-left: 18px;
}

/* .grid {
  display: grid;
  gap: 20px;
}

.stats {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}

@media (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr;
  }
} */
