.search {
  display: flex;
  align-items: flex-end;
}
.fieldList {
  display: flex;
  align-items: flex-end;
}
.field {
  flex-shrink: 0;
  width: 300px;
  margin-right: 15px;
}
.count {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--primary-color);
  margin-left: auto;
  padding-left: 30px;
  align-self: center;
  flex-shrink: 0;
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
input::placeholder {
  color: black;
  width: 155px;
}
