.root {
  /*background-color: #fff;*/
  background-color: #1B2534;
}

.nav,
.title,
.link,
.item,
.list {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.nav {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.8;
}

.logo {
  padding: 14px 19px 11px 23px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.img {
  object-fit: contain;
}

.link {
  margin-left: 22px;
}

.title {
  margin-left: 22px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.list {
  padding-top: 10px;
  border-top: 1px solid rgba(255,255,255, 0.1);
  display: flex;
  flex-direction: column;
  list-style: none;
}

.item {
  position: relative;
  padding: 12px 19px 10px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  border-left: 4px solid transparent;
  background: transparent;

  text-decoration: none;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
}

.itemHidden {
  cursor: default;
  opacity: 0.3;
}

.active,
.item:hover {
  border: none;
  border-left: 4px solid #0275B1;
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);*/
  color: #fff;
  cursor: pointer;
  background: linear-gradient(to right, rgba(2, 117, 177, 0.1), rgba(2, 117, 177, 0.6));
}
.itemHidden:hover {
  cursor: default;
  color: inherit;
}

.item:nth-last-child(2) {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.topic {
  border-left: 10px solid grey;
}

.trending_negative {
  border-left: 10px solid red;
}

.trending_positive {
  border-left: 10px solid greenyellow;
}

@media (max-width: 576px) {
  .item,
  .container {
    padding: 14px 15px;
  }
}

@media (max-width: 768px) {
  .link,
  .title {
    display: none;
  }
}
